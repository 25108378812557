@import 'theme/voith-opl';

.tab {
  @extend .typo-button;
	line-height: 22px;
	letter-spacing: 0px;
	font-size: 15px;
	color: rgb(136, 136, 136);
	padding: 0px 20px;
	border-top: 2px solid rgb(221, 221, 221);
	border-right: 2px solid rgb(221, 221, 221);
	border-left: none;
	opacity: 1;
	min-height: 40px;
	height: 40px;
	white-space: nowrap;
	text-transform: uppercase;
  &:first-child{
    border-left: none;
	}
}

.tabs {
  padding-left: 0;
	width: 100%;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
}

.tabs_scroller {
	width: 100%;
	overflow: hidden;
}

.tabs_fixed {
	flex: 1 1 auto;
	display: inline-block;
	position: relative;
	white-space: nowrap;
	&:before {
		content: "";
		position: absolute;
		top: 0px;
		height: 2px;
		left: 0;
		right: 0;
		display: block;
    background: rgb(221, 221, 221);
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 0px;
		height: 2px;
		left: 0;
		right: 0;
		display: block;
    background: rgb(221, 221, 221);
	}
}

.tabs_flexContainer {
	display: flex;
}

.active{
	height: 40px;
	color: rgb(45, 66, 117);
	background-color: rgb(255, 255, 255);
	border-bottom: 2px solid rgb(255, 255, 255);
	border-top: 2px solid rgb(45, 66, 117);
}

.selectedTab {
	background-color: #ffffff;
	width: 100%;
	height: auto;
	display: table;
}

.action {
  margin-left: 8px;
}