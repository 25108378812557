.tooltip{
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  transition: opacity .5s ease-in-out;
  text-align: left;
  &.show{
    opacity: 1 !important;
  }
}

:global {
  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.type-light.place-left:after{
    border-left-width: 10px;
  }
  .__react_component_tooltip.place-left:after{
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    right: -10px;
    margin-top: -10px;
  }
}