@import 'theme/voith-opl';

.heading{
  display:flex;
  margin-bottom: 10px;
}
.title{
  @extend .typo-label;
  margin-left: 10px;
}
.icon:before{
  color:$color-voith-black;
  position: relative;
  top: -1px;
  font-size: 18px;
}
.status{
  font-size: 18px;
  display:flex;
  max-width: 120px;
  >*{
    flex:0;
    padding: 0 5px;
  }
  >:first-child{
    padding-left:0;
  }
  >:last-child{
    padding-right:0;
  }
}
.count{
  font-size: 14px;
  margin-left: 3px;
  color: $color-voith-black;
}
.countIcon{
  position: relative;
  top: 1px;
}