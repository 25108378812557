@import 'theme/voith-opl';

.fileSelect{
  display:flex;
}

.label{
  @extend .typo-label;
  margin-bottom: 23px;
}

.input{
  display: none;
}

.selectButton{
  display: flex;
  width: 250px;
  align-items: flex-end;
  justify-content: space-between;
}

.preview{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}
.icon{
  font-size: 24px;
  margin-right: 20px;
}


.info{
  @extend .typo-text-button;
  flex:1;
  text-align: left;
  text-decoration: none;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.previewImage{
  width: 26px;
  height: 26px;
  margin-left: 40px;
}

.errorText{
  @extend .typo-label;
  margin-top: 15px;
  color: $color-gray-40;
}