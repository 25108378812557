.malfunction{
  cursor: pointer;
}
.cls1, .cls10, .cls13, .cls16, .cls17, .cls19, .cls2, .cls20, .cls21, .cls23, .cls7, .cls9 {
  fill: none;
}

.cls1 {
  stroke: #ddd;
}

.cls1, .cls10, .cls11, .cls12, .cls13, .cls14, .cls15, .cls16, .cls17, .cls2, .cls20, .cls21, .cls22, .cls23, .cls3, .cls4, .cls5, .cls6, .cls7, .cls9 {
  stroke-width: 1.5px;
}

.cls2 {
  stroke: #9c9c9c;
}

.cls3 {
  fill: #9c9c9c;
}

.cls11, .cls12, .cls13, .cls14, .cls15, .cls16, .cls17, .cls18, .cls20, .cls21, .cls22, .cls23, .cls3, .cls4, .cls5, .cls6, .cls9 {
  stroke: #202020;
}

.cls14, .cls4 {
  fill: #fff;
}

.cls22, .cls5, .cls8 {
  fill: #ddd;
}

.cls6 {
  fill: #ededed;
}

.cls7 {
  stroke: #6f6f6f;
}

.cls10 {
  stroke: #ededed;
}

.cls11, .cls18 {
  fill: #f6f6f6;
}

.cls12 {
  fill: #6f6f6f;
}

.cls13, .cls20, .cls21, .cls22, .cls23 {
  stroke-linecap: round;
}

.cls14, .cls20, .cls21, .cls22, .cls23 {
  stroke-linejoin: round;
}

.cls15 {
  fill: #202020;
}

.cls16 {
  stroke-dasharray: 2.08 2.08 2.08 2.08;
}

.cls17 {
  stroke-dasharray: 2.08 2.08 2.08 2.08 2.08 2.08;
}

.cls21 {
  stroke-dasharray: 0.02 4.05;
}

.cls23 {
  stroke-dasharray: 0.1 5.91;
}