@import 'theme/voith-opl';

.multilineInput{
  position: relative;
  width: 100%;
  max-width: $global-input-field-max-width;
}

.normal{
  min-height: 180px;
}
.small{
  min-height: 80px;
}

.textArea{
  @extend .typo-paragraph;
  border: 1px solid $color-voith-blue;
  padding: 15px;
  resize: none;
  width: calc(100% - 30px);
  transition: .2s border ease-in-out;
  min-height: 180px;
  &::placeholder{
    transition: .2s opacity ease-in-out;
  }
  &:focus{
    border: 1px solid $color-active-blue;
    outline: none;
    &::placeholder{
      opacity: 0.6;
    }  
  }
}
.label{
  @extend .typo-label;
  margin-bottom: 30px;
}
.input{
  // height: calc(100% - 60px);
}