.List{
}

.Spinner{
  margin: 60px 0;
}

.entry{
  margin: 20px 0;
}
