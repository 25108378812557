@import 'theme/voith-opl';

.screen{
  padding:0;
  margin:0;
  position: relative;
}
.title{
  position: fixed;
  right: 20px;
  top:20px;
  z-index: 999;
}
.sectionActionButton{
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statusBar{
  background-color:white
}
.tooltip{
  padding: 0;
}
.tagList{
  border: 1px solid $color-gray-20;
}
.attachedCards{
  border: 1px solid $color-gray-20;
  border-top: none;
}
.border{
  border: 1px solid $color-gray-20;
}

.noPadding{
  padding: 0;
}

.svgTurbineSection {
  padding: 80px 0px 0px 0px;
}

.logoVoithDiv {
  position: fixed;
  display: none;
  top: 15px;
  right: 15px;
  width: 198px;
  height: 48px;
  z-index: 999;
}

.logoVoith {
  width: 100%;
  height: 100%;
}

@media print {
  .navigationBar {
   display: none;
  }
  .logoVoithDiv {
    display: block;
  }
  @page {
    size:  auto; // auto is the initial value
    margin: 0mm; // this affects the margin in the printer settings
  }
  html {
    background-color: #FFFFFF; 
    margin: 0px; // this affects the margin on the html before sending to printer
  }
  body {
    background-color: white;
    -webkit-print-color-adjust: exact;
    margin: 20px 5px 20px 5px; // margin you want for the content
  }
}
