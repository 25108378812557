@import 'theme/voith-opl';

.tagSection{
  padding: $global-padding;
}
.tagSectionHeading{
  margin-bottom: 21px;
}
.tagSectionIcon{
  margin-right: 13px;
}
.tagList{
  display:flex;
  flex-wrap: wrap;
}
.tagListEntry{
  margin: 5px;
}
.tagListEntryAnomaly{
  margin: 5px;
  color: black;
}
.tag{
  cursor: pointer;
}
.tooltip{
  padding: 0;
}