@import 'theme/voith-opl';

.progressBar{
  width: 100%;
  height: 20px;
  background-color: $color-gray-20;
}

.progress{
  transition: .1s width ease-in-out; 
  height: 20px;
  width:0;
  background-color: $color-voith-blue-hover;
}