@import 'theme/voith-opl';
.containerMinWidth{
  min-width: 350px;
}

.details{
  border-top: 2px solid $color-gray-10;
}
.title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleIcon{
  font-size: 16px;
  margin-left: 10px;
}
.title,
.description,
.hsiContainer
{
  padding: 12px 16px;
}
.description{
  @extend .typo-button;
}
.disabled{
  color: $color-gray-20;
}
.hsi{
  flex:1;
  font-size: 24px;
  margin-left: 16px;
}
.hsiLabel{
  position: relative;
  top: 6px;
  color: $color-gray-60;
}
.hsiContainer{
  display:flex;
  align-items: center;
}