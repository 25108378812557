@import 'theme/voith-opl';

.figure{
  display: flex;
  flex-direction: column;
  margin:0;
}

.image{
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.imageContainer{
  border-radius: 4px;
  border: 4px solid $color-gray-10;
  padding: 32px 50px;
}

.spinner{
  min-height: 400px;
}

.caption{
  @extend .typo-label;
  color: $color-gray-40;
  margin: 30px 0;
}