@import 'theme/voith-opl';

.form {
  width: 100%;
  background-color: $color-white;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
}

.input {
  width: 100%;
  font-size: 16px;
}