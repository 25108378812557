@import 'theme/voith-opl';

.container{
  position: fixed;
  top: 0;
  left:0;
  z-index:9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  display:flex;
  justify-content: center;
  align-items: center;
  animation: backdrop-fade-in .2s;
}
.inner{
  width: 700px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  opacity: 0;
  animation: modal-animation .3s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  border-top:5px solid currentColor;
}
.close{
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 32px;
  ::before{
    color:currentColor
  }
}
.title{
  @extend .typo-paragraph-highlight;
  text-transform: none;
  align-self: flex-start;
  flex: 0;
  margin-bottom: 25px;
}
.message{
  @extend .typo-button;
  align-self: flex-start;
  flex:1;
  color: $color-gray-40;
  max-width: 300px;
}
.buttons{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.wideButton{
  width: 100%;
}
.toggleButton{
  margin: 15px 0;
}
.warning{
  color:$color-yellow-warning;;
}
.error{
  color:$color-red-error;
}
.alert{
  color:$color-voith-blue;
}
.wait{
  justify-content: center;
  border-top:none;
}

@keyframes modal-animation{
  0%{
    transform: translateY(-50px);
    opacity:0;
  }
  100%{
    transform: translateY(0);
    opacity: 10;
  }
}
@keyframes backdrop-fade-in{
  0%{
    background-color: rgba(0,0,0,0);
  }
  100%{
    background-color: rgba(0,0,0,0.5);
  }
}

.content {
  text-transform: none;
  align-self: flex-start;
  flex: 0;
  margin-bottom: 25px;
  width: 100%;
  h4 {
    font-family: "HelveticaNeueMedium";
    color: $color-primary;
    font-size: 14px;
    line-height: 20px;
  }
}

.label{
  @extend .typo-label;
  margin-bottom: 15px;
}

.input{
  @extend .typo-paragraph;
  width: 60%;
  border-bottom: 1px solid $color-voith-blue-hover;
  padding: 15px 0;
  transition: .2s border ease-in-out;
  resize: none;
  &::placeholder{
    transition: .2s opacity ease-in-out;
  }
  &:focus{
    border-bottom: 1px solid $color-active-blue;
    outline: none;
    &::placeholder{
      opacity: 0.6;
    }
  }
}

.error{
  .input{
    border-bottom: 1px solid $color-gray-40;
    &:focus{
      border-bottom: 1px solid $color-gray-40;
      outline: none;
    }
  }
}

.errorText{
  @extend .typo-label;
  position: absolute;
  color: $color-gray-40;
  bottom: -25px;
}

.spinner{
  height: 300px;
  width: 100%;
  background-color:white;
}

.inputContainer{
  position: relative;
  align-items: stretch;
  width: 100%;
  display:flex;
  flex-direction: column;
  max-width: $global-input-field-max-width;
}

.divTranslations {
  display: flex;
  margin-top: 30px;

  button {
    margin-left: 30px;
    color: $color-voith-blue;
    display: flex;

    p {
      font-family: "HelveticaNeueMedium";
      margin-left: 8px;
      font-size: 12px;
      line-height: 14px;
      margin-top: 5px;
    }
  }
}

.btn {
  width: 130px;
  min-width: 130px;
}

.item {
  margin-bottom: 30px;
}

.translations {
  p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray-40;
  }
}

.type {
  display: flex;
  h4 {
    width: 170px;
    margin-top: 15px;
  }
}

.dropdown {
  width: 150px;
}