@import 'theme/voith-opl';

.multilineInput{
  position: relative;
  width: 100%;
  max-width: $global-input-field-max-width;
}

.normal{
  min-height: 400px;
}
.small{
  min-height: 200px;
}

.textArea{
  font-family: monospace;
  font-size: 16px;
  line-height: 18px;
  border: 1px solid $color-voith-blue;
  padding: 15px;
  resize: vertical;
  width: calc(100% - 30px);
  &:focus{
    border: 1px solid $color-active-blue;
    outline: none;
  }
}
.label{
  @extend .typo-label;
}
.input{
  left: 0;
  width: 100%;
}
.preview{
  border: 1px solid $color-voith-blue;
}
.fileUpload{
  margin-top: 20px;
}