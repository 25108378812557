@import 'theme/voith-opl';

.buttons{
  position: absolute;
  right: 20px;
  top: 20px;
  > button{
    margin: 0 5px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.section{
  padding: 40px 80px;
}
.sectionCardContent{
  padding: 0 80px;
}
.sectionTitle{
  @extend .typo-small-sub-headline;
  color: $color-gray-40;
  margin-bottom: 32px;
}
.sectionTitleLarge{
  @extend .typo-card-title;
  margin-bottom: 32px;
}
.sectionTitleHuge{
  @extend .typo-headline-small;
  line-height: 52px;
  margin-bottom: 32px;
}
.structurePath{
  margin-bottom: 64px;
}
.malfunctionTitle{
  margin-bottom: 24px;
}
.roundParagraph{
  padding: 10px 60px;
  border: 2px solid currentColor;
  border-radius: 4px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  position: relative;
  p{
    color: $color-voith-black;
  }
}
.roundParagraphIcon{
  font-size: 28px;
  line-height: 28px;
  position: absolute;
  left: 20px;
  &::before{
    color: $color-voith-black;
  }
}
.close{
  font-size: 32px;
}
.edit{
  font-size: 28px;
}
.argumentations{
  padding: 0 15px;
}