@import 'theme/voith-opl';

* {
  .view {
    min-height: 0% !important;
    margin-bottom: none !important;
    height: 100% !important;
  }
}

.container {
  padding:20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;

  h3 {
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-blue;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-blue;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-black;
  }
}

.spinner{
  width: 100%;
  background-color:white;
  min-height: 794px;
}

.spinner1{
  width: 100%;
  background-color:white;
  min-height: 591px;
}

.screen {
  display: flex;
  background: $color-white;
  margin: 20px auto;

  .malfunction {
    width: 284px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 14px 5px;
    overflow-y: scroll;
    max-height: 800px;
  }

  .svg {
    width: 867px;
    min-width: 867px;
    max-width: 867px;
    min-height: 794px;
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;
      margin: 36px 24px 82px;
      overflow-x: auto;

      .btn {
        min-width: 130px;
        padding: 8px 15px;
        margin-right: 10px;
        font-size: 12px;
      }

      .active {
        background: $color-primaryActive;
      }
    }
  }
}

.dragComponent {
  font-size: 14px;
  line-height: 20px;
}