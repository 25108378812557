@import 'theme/voith-opl';

.container{
  background-color: white;
  border-radius: 12px;
  transition: .3s box-shadow ease-in-out;
  border: 5px solid $color-gray-10;
}

.containerLink{
  cursor: pointer;
  &:hover{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  }
}

.header{
  @extend .typo-label;
  height: 60px;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 15px 20px 25px;
}

.messageBody{
  padding: 25px;
  border-top: 2px solid $color-gray-10;
}

.headerIcon{
  position: relative;
  top: -2px;
  color: $color-voith-blue;
  font-size: 20px;
  margin-right: 10px;
}

.user{
  margin: 0;
}

.meta{
  @extend .typo-paragraph-label;
  strong{
    @extend .typo-label;
  }
}

.batch{
  margin: 0 10px;
}
.actionButton{
  color: $color-voith-blue;
  justify-self: flex-end;
  font-size: 20px;
}

.date{
  @extend .typo-paragraph-label;
  color: $color-gray-40;
  margin: 0 10px;
  flex: 1;
  text-align: right;
}

.fileList{
  @extend .typo-label;
  padding: 0 0 20px 20px;
  li{
    padding: 15px;
  }
}

.download{
  color: $color-voith-blue;
  display:flex;
  align-items: flex-start;
  span{
    position: relative;
    top: -2px;
    font-size: 20px;
    margin-right: 10px;
  }
}