@import 'theme/voith-opl';

.title{
  margin-bottom: 20px;
  display:flex;
  align-items: center;
  h5{
    @extend .typo-label;
  }
  span{
    font-size: 22px;
    &:before{
      color:$color-gray-40;
    }
    margin-right: 5px;
  }
}
.default,.small,.hidden{
  padding: 15px;
}
.default{
  @extend .markdownDefault;
  @extend .typo-paragraph;
}
.hidden{
  color: $color-gray-40;
  @extend .markdownDefault;
  @extend .typo-pop-up-content;  
}
.small{
  @extend .markdownDefault;
  @extend .typo-pop-up-content;  
}
.noPadding{
  padding: 0;
}
