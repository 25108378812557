@import 'theme/voith-opl';

.tab {
  @extend .typo-button;
  display: inline-block;
  list-style: none;
  text-transform: uppercase;
  padding: 0em 1em 1.5em 1em;
  color: $color-gray-40;
  &:focus{
    color: $color-active-blue;
  }
  &:first-child{
    padding-left: 0;
  }
  &:last-child{
    padding-right: 0;
  }
  &:hover{
    color: $color-voith-blue
  }
}

.tabs {
  padding-left: 0;
}

.active{
  color: $color-voith-blue;
  position:relative;
}
