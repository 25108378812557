@import 'theme/voith-opl';
.status{
  flex:1 0 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.statusSmall{
  flex:1 0 50%;
  display: flex;
  align-items: center;
}
.statusTitle{
  display: flex;
  flex-direction: column;
}
.label{
  margin-bottom: 10px;
}
.label,
.typeSmall
{
  @extend .typo-label;
}
.type{
  @extend .typo-pop-up-content;
}
.statusIcon{
  font-size: 32px;
  margin-right: 15px;
}