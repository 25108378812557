@import 'theme/voith-opl';
.status{
  @extend .typo-pop-up-content;
  font-size: inherit;
  display: flex;
  align-items: center;
  transition: opacity .3s ease-in-out;
}
.title{
  @extend .typo-label;
  margin-bottom: 0.5em;
  color: initial;
}
.icon{
  font-size: 2.5em;
  position: relative;
  margin-left: 0.5em;
  &::before{
    color: $color-voith-black;
  }
}
.count{
  @extend .typo-headline-small;
  font-size: 3em;
  color: inherit;
}

.status.disabled{
  color: $color-voith-black;
  opacity: 0.1;
}