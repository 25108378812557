@import 'theme/voith-opl';

.deleteButton{
  margin-top: 0;
}
.fileSelectRow{
  max-width: $global-input-field-max-width;
}
.dropDown{
  flex:1;
}
.fileSelect{
  flex:4;
  padding-right: 20px;
}
.checkBox{
  flex:1;
}