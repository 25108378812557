@import 'theme/voith-opl';

header{
  height: 80px;
  background-color: transparent;
  display: flex;
  align-items: center;
}
.left,.right{
  display:flex;
}
.home{
  color: $color-voith-blue;
  a{
    font-size: 32px;
    position: relative;
    margin-right: 15px;
  }
}
.malfunction{
  color: $color-voith-blue;
  a{
    font-size: 32px;
    position: relative;
    margin-right: 15px;
    margin-left: 15px;
  }
}
.title{
  @extend .typo-small-sub-headline;
}
.left{
  flex: 1;
  align-items: center;
}
.right{
  flex: 0 0 300px;
  justify-content: flex-end;
}
.logo{
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.navigation{
  display:flex;
}
.navigationItem{
  @extend .typo-pop-up-content;
  color: $color-voith-blue;
  display: flex;
  align-items: center;
  margin-right: 5px;
  span{
    color: $color-voith-blue;
    margin: 0 10px;
    font-size: 24px;
  }
}

.selectMalfunction {
  display: flex;

  span {
    margin-top: 8px;
  }

  .dropdown {
    width: 250px;
  }
}