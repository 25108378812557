@import 'theme/voith-opl';

h4 {
  font-family: "HelveticaNeueMedium";
  color: $color-primary;
  font-size: 14px;
  line-height: 20px;
}

.inputContainer {
  position: relative;
  align-items: stretch;
  width: 100%;
  display:flex;
  flex-direction: column;
  max-width: $global-input-field-max-width;
}

.dropdown {
  button {
    svg {
      path {
        fill: $color-primary !important;
      }
    }
  }
}