@import 'theme/voith-opl';

.container{
  position: relative;
  display:flex;
  flex-direction: column;
  max-width: $global-input-field-max-width;
}

.label{
  @extend .typo-label;
  margin-bottom: 15px;
}

.input{
  @extend .typo-paragraph;
  width: calc(100% - 30px);
  border-bottom: 1px solid $color-voith-blue-hover;
  padding: 15px 0;
  transition: .2s border ease-in-out;
  resize: none;
  &::placeholder{
    transition: .2s opacity ease-in-out;
  }
  &:focus{
    border-bottom: 1px solid $color-active-blue;
    outline: none;
    &::placeholder{
      opacity: 0.6;
    }
  }
}

.error{
  .input{
    border-bottom: 1px solid $color-gray-40;
    &:focus{
      border-bottom: 1px solid $color-gray-40;
      outline: none;
    }
  }
}

.errorText{
  @extend .typo-label;
  position: absolute;
  color: $color-gray-40;
  bottom: -25px;
}