@import 'theme/voith-opl';
.container{
  display:flex;
  align-items: flex-end;
  font-size: 64px;
}
.indicator{
  position: relative;
  width: 100%;
  height: 1em;
  border-bottom: 1px solid $color-gray-20;
  display:flex;
  align-items: flex-end;
}
.scale{
  flex: 1;
  position: relative;
}
.scaleIndex{
  position: absolute;
  display:block;
  width: 2px;
  height: 0.5em;
  top: -0.5em;
  background-color: $color-gray-20;
}
.valueBar{
  position: absolute;
  height: 0.2em;
  background-color: currentColor;
}

.value{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  line-height: 1em;  
  font-size: 1em;
  color: currentColor;
  padding-left: 1em;
}
.warning{
  color: $color-yellow-warning;
}
.alert{
  color: $color-red-error;
}
.normal{
  color: $color-voith-blue;
}