@import "theme/voith-opl";

.container,
.containerSmall,
.containerHuge,
.containerLarge
{
  display:flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  flex-wrap: wrap;
  border-bottom: 1px solid $color-gray-20;
  padding: 15px $global-padding;
}
.containerLarge{
  @extend .typo-card-title;
  height: 70px;
}
.containerHuge{
  @extend .typo-card-title;
  height: 108px;
}
.container{
  height: 60px;
}
.containerSmall{
  @extend .typo-paragraph-highlight;
  font-size: 12px;
  height: 50px;
}
.noBorder{
  border-bottom:none;
}

.marginTop{
  margin-top: 20px; 
}
.actionButton{
  font-size: 32px;
}
.actionButtonSmall{
  font-size: 24px;
}
.subline{
  flex: 1 0 100%;
  color: $color-gray-40;
  @extend .typo-pop-up-content;
}