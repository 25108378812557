@import 'theme/voith-opl';

.container{
  padding: 25px 50px;
  position: absolute;
  animation: snackbar-animation .5s;
}

@keyframes snackbar-animation{
  0%{
    transform: translateY(50px);
    opacity:0;
  }
  100%{
    transform: translateY(0);
    opacity: 10;
  }
}

.inner{
  position: relative;
  min-width: 430px;
  min-height: 96px;
  box-shadow: 4px 9px 26px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  padding: 28px 20px;
  border-top:5px solid currentColor;
}

.close{
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 28px;
  color: currentColor;
  ::before{
    color:currentColor;
  }
}

.title{
  @extend .typo-paragraph-highlight;
  text-transform: capitalize;
}

.text{
  @extend .typo-button;
  color: $color-gray-40;
}

.warning{
  color:$color-yellow-warning;
}
.error{
  color:$color-red-error;
}
.alert{
  color:$color-voith-blue;
}
