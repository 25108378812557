@import 'theme/voith-opl';

.spinner{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.outer{
  animation: baseRotation 1600ms infinite linear;
  transform-origin:50% 50%;
}
.inner{  
  animation: fastRotation 8000ms infinite cubic-bezier(.82,0,.7,1);
  transform-origin:50% 50%;
}

.message{
  @extend .typo-small-sub-headline;
  margin-top: 50px;
  color: $color-gray-40;
}

.circ{
  fill:none;
  stroke-width:2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 10,126;
  transform-origin:50% 50%;
  /*stroke-linecap:round;*/
}
.a{
  composes: circ;
  transform: rotate(0deg);
  animation: lengthA 8000ms infinite cubic-bezier(.82,0,.7,1);
  stroke:#2D4275;
}
.b{
  composes: circ;
  transform: rotate(40deg);
  animation: lengthB 8000ms infinite cubic-bezier(.82,0,.7,1);
  stroke:#15D4F1;
}
.c{
  composes: circ;
  transform: rotate(-35deg);
  animation: lengthC 8000ms infinite cubic-bezier(.82,0,.7,1);
  stroke:#2D4275;
}
.innerA{
  transform-origin:50% 50%;
}
.innerB{
  transform-origin:50% 50%;
  
}
.innerC{
  transform-origin:50% 50%;
}

@keyframes baseRotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
@keyframes fastRotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(3600deg);
    }
}

@keyframes lengthA {
  0% {
      stroke-dasharray: 5,126;
      transform: rotate(0deg);
    }
    75% {
      stroke-dasharray: 130,126;
      transform: rotate(0deg);
    }
    100% {
      stroke-dasharray: 5,126;
      transform: rotate(360deg);
    }
}
@keyframes lengthB {
  0% {
      stroke-dasharray: 5,126;
      transform: rotate(40deg);
    }
    70% {
      stroke-dasharray: 85,126;
      transform: rotate(40deg);
    }
    100% {
      stroke-dasharray: 5,126;
      transform: rotate(400deg);
    }
}
@keyframes lengthC {
  0% {
      stroke-dasharray: 5,126;
      transform: rotate(-35deg);
    }
    80% {
      stroke-dasharray: 90,126;
      transform: rotate(-35deg);
    }
    100% {
      stroke-dasharray: 5,126;
      transform: rotate(325deg);
    }
}