@import 'theme/voith-opl';

.container{
  padding: $global-padding;
}

.icon{
  font-size: 20px;
}

.statusList{
  display:flex;
}
.statusListItem{
  cursor: pointer;
  margin: 0 10px;
  &:first-child{
    margin-left:0;
  }
  &:last-child{
    margin-right:0;
  }
}
.tooltip{
  padding: 0;
}