@import 'theme/voith-opl';

.container {
  padding:20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;

  h3 {
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-blue;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-blue;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-black;
  }
}

.spinner{
  width: 100%;
  position: absolute;
  background-color:white;
}