@import 'theme/voith-opl';

.button{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonLarge{
  background-color:white;
  color: $color-voith-blue;
  width: 100%;
  padding: 40px;
  border-radius: 12px;
  border: 5px solid $color-gray-10;
}
.icon{
  font-size: 32px;
  margin-bottom: 15px;
}
.title{
 @extend .typo-small-sub-headline;
 color: $color-gray-40;
}