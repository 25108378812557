@import 'theme/voith-opl';

.dropDown{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: $global-input-field-max-width;
}
.label{
  @extend .typo-label;
  margin-bottom: 15px;
}
.header{
  display:flex;
  justify-content: space-between;
}
.arrowUp{
  display: inline-block;
  transform: rotate(180deg);
  margin: 0 15px;
}
.arrowDown{
  display: inline-block;
  margin: 0 15px;
}
.button{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
}
.placeholder{
  @extend .typo-text-button;
  text-decoration: none;
  padding: 15px;
}
.container{
  position: absolute;
  z-index:1;
  top: 0;
  left: 0;
  overflow-y: scroll;
  max-height: 600px;
  width: 100%;
  background-color: white;
  box-shadow: 4px 9px 26px 0 rgba(0, 0, 0, 0.16);
}
.list{
  >li{
    >ul{
      margin-left: 20px;
    }
  }
}
.listItem{
  @extend .typo-text-button;
  text-decoration: none;
  cursor: pointer;
  padding: 15px;
  &:hover{
    color:white;
    background-color: $color-voith-blue-hover;
  }  
}
.selected{
  color:white;
  background-color: $color-voith-blue-hover;
}
.levelDisabled{
  pointer-events: none;
}
.listItemDisabled{
  color: $color-gray-20;
  pointer-events: none;
}
.icon{
  margin-right: 5px;
}
.error{
}