@import 'theme/voith-opl';

.tag{
  display: inline-flex;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 12px;
  letter-spacing: 0.48px;
  font-family: "HelveticaNeueMedium";
  text-transform: uppercase;
}
.small{
  padding: 5px 15px;
  font-family: "HelveticaNeueRegular";
}
.default{
  color: $color-gray-60;
  background-color: $color-gray-20;
}
.draft{
  color:white;
  background-color: $color-active-blue;
}
.latest{
  color: $color-gray-60;
  background-color: $color-gray-20;
}
.warning{
  color:white;
  background-color: $color-yellow-warning;
}
.alert{
  color:white;
  background-color: $color-red-error;
}
.normal{
  color:white;
  background-color: $color-voith-blue;
}
.OPL{
  color:white;
  background-color: $color-voith-blue;
}
.HPP{
  color:white;
  background-color: $color-active-blue;
}
