@import 'theme/voith-opl';
.containerMinWidth{
  min-width: 350px;
}
.gridHeader {
  padding-top: 10px;
  padding-bottom: 10px;
}
.groupTitle {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
.groupIcon {
  font-size: 16px;
  text-align: right;
}
.gridRow {
  padding-top: 5px;
  padding-bottom: 5px;
}
.gridRowBorder {
  border-top: 2px solid $color-gray-10;
}
.gridRowMalfunctionName {
  font-size: 11px;
  text-align: left;
  padding-top: 3px;
}
.gridRowCardStatusIcon {
  padding: 0px 0px;
  text-align: center;
  font-size: 17px;
}
.spanStatusIcon {
  width: 24px;
  height: 24px;
}
.gridRowCardAddEditIcon {
  padding: 0px 4px;
  text-align: right;
}
.gridRowCardAddEditIcon:hover {
  opacity: 0.4;
}
