@import 'theme/voith-opl';

/** Lightwheight Reset **/

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a,
a:active{
  text-decoration: none;
  color: inherit;
}

button{
  cursor: pointer;
}

.no-scroll{
  overflow:hidden;
  margin-left: -14px;
}


/* ----------------------------------------------------------------------------------------------------
https://gist.github.com/mjlescano/fc472f778e77c4b760ab

Super Form Reset

A couple of things to watch out for:

- IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
- The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borderTop.
- You NEED to set the font-size and family on all form elements
- Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
- You can style the upload button in webkit using ::-webkit-file-upload-button
- ::-webkit-file-upload-button selectors can't be used in the same selector as normal ones. FF and IE freak out.
- IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
- By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs

----------------------------------------------------------------------------------------------------*/

input,
label,
select,
button,
textarea,
fieldset
{
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  /* white-space: normal; */
  background: none;
  line-height: 1;

}

/* Remove the stupid outer glow in Webkit */
input:focus,
button:focus {
  outline: 0;
  -webkit-focus-ring-color: none;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


/* Button Controls
-----------------------------------------------*/

/* input[type=checkbox],
input[type=radio] {
  width: 13px;
  height: 13px;
} */


/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-----------------------------------------------*/

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance: none) {

    input::placeholder {
      position: relative;
      top: -4px;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}


/** Primary Fonts */
@font-face{
  font-family: "HelveticaNeueRegular";
  src:url("./assets/fonts/HelveticaNeue_eTex/e30c4534-e8af-416e-b7fb-c395323e8424.eot?#iefix");
  src:url("./assets/fonts/HelveticaNeue_eTex/e30c4534-e8af-416e-b7fb-c395323e8424.eot?#iefix") format("eot"),
  url("./assets/fonts/HelveticaNeue_eTex/567e6731-e9dc-4a22-9a78-6348be826b52.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeue_eTex/541908c1-6856-4337-b6cb-f57a88784cbe.woff") format("woff"),
  url("./assets/fonts/HelveticaNeue_eTex/354b6f86-00e3-4843-b8d0-9107b4ae0100.ttf") format("truetype"),
  url("./assets/fonts/HelveticaNeue_eTex/2e170156-a84c-48cf-bc19-5c5c4db18b03.svg#2e170156-a84c-48cf-bc19-5c5c4db18b03") format("svg");
}
@font-face{
  font-family: "HelveticaNeueMedium";
  src:url("./assets/fonts/HelveticaNeue_eTex/de8ea7a9-5466-4dc7-b7ab-af0e574abc59.eot?#iefix");
  src:url("./assets/fonts/HelveticaNeue_eTex/de8ea7a9-5466-4dc7-b7ab-af0e574abc59.eot?#iefix") format("eot"),
  url("./assets/fonts/HelveticaNeue_eTex/4424be58-410b-4308-8f6e-fc7783a33540.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeue_eTex/b9737fe6-d319-40cb-86ee-6ba0882bd5c2.woff") format("woff"),
  url("./assets/fonts/HelveticaNeue_eTex/2e97165a-0bc2-4d7b-932e-469416453152.ttf") format("truetype"),
  url("./assets/fonts/HelveticaNeue_eTex/599c2c22-db49-4454-97d0-6bb81892c795.svg#599c2c22-db49-4454-97d0-6bb81892c795") format("svg");
}

/** Icon Fonts */
@font-face {
  font-family: 'voith_icons';
  src:  url('./assets/fonts/Voith_Icon_Font/voith-opl-icons.eot?ql25hg');
  src:  url('./assets/fonts/Voith_Icon_Font/voith-opl-icons.eot?ql25hg#iefix') format('embedded-opentype'),
    url('./assets/fonts/Voith_Icon_Font/voith-opl-icons.ttf?ql25hg') format('truetype'),
    url('./assets/fonts/Voith_Icon_Font/voith-opl-icons.woff?ql25hg') format('woff'),
    url('./assets/fonts/Voith_Icon_Font/voith-opl-icons.svg?ql25hg#voith_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'voith_icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-add:before {
  content: "\e902";
}
.icon-add_comment:before {
  content: "\e903";
}
.icon-calendar:before {
  content: "\e913";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-arrow-right:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e919";
}
.icon-cloud_upload:before {
  content: "\e91a";
}
.icon-delete_01:before {
  content: "\e927";
}
.icon-edit:before {
  content: "\e92f";
}
.icon-group:before {
  content: "\e947";
}
.icon-help_01:before {
  content: "\e948";
}
.icon-home:before {
  content: "\e94d";
}
.icon-import:before {
  content: "\e950";
}
.icon-info:before {
  content: "\e951";
}
.icon-invisible:before {
  content: "\e952";
}
.icon-KPI:before {
  content: "\e953";
}
.icon-logout:before {
  content: "\e962";
}
.icon-more:before {
  content: "\e968";
}
.icon-notification_warning:before {
  content: "\e96d";
}
.icon-search:before {
  content: "\e993";
}
.icon-settings_01:before {
  content: "\e994";
}
.icon-settings_02:before {
  content: "\e995";
}
.icon-update_view:before {
  content: "\e9aa";
}
.icon-warning:before {
  content: "\e9ba";
  color:$color-yellow-warning;
}
.icon-status_alarm_small:before {
  content: "\e9c4";
  color:$color-red-error;
}
.icon-status_alarm:before {
  content: "\e9c5";
  color: $color-red-error;
}
.icon-status_invalid_small:before {
  content: "\e9c6";
  color: $color-gray-20;
}
.icon-status_invalid:before {
  content: "\e9c7";
  color: $color-gray-20;
}
.icon-status_ok_small:before {
  content: "\e9c8";
}
.icon-status_ok:before {
  content: "\e9c9";
  color: $color-voith-blue;
}
.icon-status_warning_small:before {
  content: "\e9ca";
  color: $color-yellow-warning;
}
.icon-status_warning:before {
  content: "\e9cb";
  color: $color-yellow-warning;
}
.icon-highlight:before {
  content: "\e90e";
}
.icon-documentation:before {
  content: "\e92c";
}
.icon-browser_error:before {
  content: "\e910";
}
.icon-comment:before {
  content: "\e91b";
}
.icon-copy_01:before {
  content: "\e91e";
}
.icon-measuring_display:before {
  content: "\e967";
}
.icon-print:before {
  content: "\e97f";
}
.icon-timespan:before {
  content: "\e9a1";
}
.icon-volume_loud:before {
  content: "\e9b5";
}
.icon-plus:before {
  content: "\002B";
  font-size: 25px;
}
.icon-malfunctions:before {
  content: url("./assets/svgs/icon_malfunction_stroke.svg");
  margin-left: 20px;
}
.icon-add_02 {
  content: url("./assets/svgs/icon_add_02.svg");
}
.icon-arrow_right_01 {
  content: url("./assets/svgs/icon_arrow_right_01.svg");
}
.icon-check {
  content: url("./assets/svgs/icon_check.svg");
}