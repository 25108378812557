$color-voith-blue: #2D4275;
$color-voith-blue-hover: #266693;
$color-voith-black: #202020;
$color-voith-gray-60: #888888;

$color-gray-60: #6F6F6F;
$color-gray-40: #9C9C9C;
$color-gray-20: #DDDDDD;
$color-gray-10: #EDEDED;

$color-active-blue: #15D4F1;

$color-yellow-warning: #FFDB33;
$color-yellow-warning-hover: #FFD200;

$color-red-error: #FF0032;
$color-red-error-hover: #FF3C61;

$color-text: $color-voith-black;

$color-primary: #2d4275;
$color-primaryHover: #266693;
$color-primaryActive: #15d4f1;
$color-white: #fff;