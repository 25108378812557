@import "theme/voith-opl";

.container{
  position: fixed;
  top: 0;
  left:0;
  z-index:9999;
  width: 100vw;
  height: 100vh;
  background-color: #00000099;
  display:flex;
  justify-content: center;
  align-items: flex-start;
  animation: backdrop-fade-in .2s;
  padding: 20px;
  overflow-y:scroll;
  -webkit-overflow-scroll: touch;
}
.inner{
  flex:1;
  max-width: 1150px;
  background-color: white;
  position: relative;
  opacity: 0;
  animation: modal-animation .3s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  padding: 60px;
}
.buttons{
  position: absolute;
  right: 20px;
  top: 20px;
  > button{
    margin: 0 5px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.close{
  font-size: 32px;
}
.edit{
  font-size: 28px;
}

@keyframes modal-animation{
  0%{
    transform: translateY(-50px);
    opacity:0;
  }
  100%{
    transform: translateY(0);
    opacity: 10;
  }
}
@keyframes backdrop-fade-in{
  0%{
    background-color: rgba(0,0,0,0);
  }
  100%{
    background-color: rgba(0,0,0,0.5);
  }
}