.cardGrid{
  $gutter-width: 1px;
  display: flex;
  flex-wrap: wrap;
  // padding: $gutter-width/2 0;
  >* {
    flex: 1 0 50%;
    max-width: 50%;
    &:nth-child(2n){
      padding: $gutter-width/2 0 $gutter-width/2 $gutter-width/2;
    }
    &:nth-child(2n+1){
      padding: $gutter-width/2 $gutter-width/2 $gutter-width/2 0;
    }
    &:nth-child(1),
    &:nth-child(2)
    {
      padding-top:0px;
    }
  }
}