@import 'theme/voith-opl';
.title{
  @extend .typo-label;
  margin-bottom: 10px;
}
.icon{
  font-size: 18px;
  position: relative;
  top: 1px;
  margin-right: 10px;
}
.status{
  @extend .typo-pop-up-content;
}