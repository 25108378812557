@import 'theme/voith-opl';

.card {
  width: 269px;
  padding: 10px 16px;
  margin: 8px 4px;
  border: solid 1px $color-gray-20;
  height: 163px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.header {
  h4 {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-blue;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-black;
  }
}

.details {
  h4 {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-blue;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-black;
  }

  .actions {
    float: right;

    span {
      font-size: 24px;
      color: $color-voith-blue;
      margin-left: 10px;
    }
  }
}