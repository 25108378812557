@import 'theme/voith-opl';

.label{
  @extend .typo-label;
  margin-bottom: 15px;
}

.hasError{
  color: $color-red-error;
}

.error{
  @extend .typo-label;
  color: $color-red-error;
}

.quite{
  color: $color-gray-40;
}