@import 'theme/voith-opl';

.dateEntry{
  display:flex;
  flex-direction: column;
}

.label{
  @extend .typo-label;
  margin-bottom: 15px;
}
.delimeter{
  padding: 15px;
  &:after{
    content: "-"
  }
}

.inputYear,
.inputMonth,
.inputDay
{
  @extend .typo-paragraph;
  border-bottom: 1px solid $color-voith-blue-hover;
  padding: 1px 0;
  text-align: center;
  transition: .2s border ease-in-out;
  &::placeholder{
    @extend .typo-paragraph;
  }
  &:focus{
    border-bottom: 1px solid $color-active-blue;
    outline: none;  
  }
}

.inputYear{
  width: 45px;
}

.inputMonth,
.inputDay
{
  width: 30px;
}

.invalid{
  .inputYear,
  .inputMonth,
  .inputDay
  {
    border-bottom: 1px solid $color-red-error-hover;
  }
}