@import 'theme/voith-opl';
.container{
  height: 250px;
  cursor: pointer;
}
.inner{
  background-color: white;
  width: 100%;
  height: 100%;
  display:flex;
  flex-wrap:wrap;
  padding: $global-padding*1.5 $global-padding;
}
.heading{
  .title{
    @extend .typo-pop-up-content;
    margin-bottom: 15px;
  }
  .subTitle{
    overflow-wrap: break-word;
    @extend .typo-card-title;
  }
}
.top,
.bottom
{
  flex: 1 1 100%;
  display: flex;
}
.top{
  align-items: flex-start;
}
.bottom{
  align-items: flex-end;
}
.topLeft,
.topRight,
.bottomLeft,
.bottomRight{
  flex:1;
}
.topRight,
.bottomRight{
  text-align:right;
}