@import "theme/voith-opl";

.container svg{
  width: 100%;
}
.tooltip{
  padding: 0;
}
.spinner{
  height: 900px;
  width: 100%;
  position: absolute;
  background-color:white;
}
:global{
  .malfunction{
    transform-origin: 50% 50% 0px;
  }
  .malfunction:hover #test{
    opacity: 1;
  }
}