@import 'theme/voith-opl';
.caption{
  @extend .typo-label;
  margin-bottom: 20px;
}
.title{
  @extend .typo-card-title;
  padding: 25px 0 15px 0;
}
.details{
  padding: 25px 0;
}

.section {
  display: flex;

  p {
    font-family: "HelveticaNeueMedium";
    margin-top:14px;
    color: $color-voith-blue;
    line-height: 20px;
    font-size: 14px;
  }
}

.dropdown {
  margin-left: 20px;
  width: 150px;
}