.typo-label{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.typo-paragraph-label{
  font-family: "HelveticaNeueRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.typo-pop-up-content{
  font-family: "HelveticaNeueRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.typo-small-sub-headline{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.typo-button{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.7px;
  // text-transform: uppercase;
}

.typo-button-small{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.7px;
  // text-transform: uppercase;
}

.typo-text-button{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
  color: $color-voith-blue;
}

.typo-paragraph{
  font-family: "HelveticaNeueRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.typo-paragraph-highlight{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.typo-card-title{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.5px;
}

.typo-headline-small{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 44px;
  letter-spacing: -0.01em;
}

.typo-headline-medium{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
}

.typo-headline-large{
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 80px;
}