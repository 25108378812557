@import 'theme/voith-opl';

.screen{
  padding:0;
  margin:0;
}
.sectionActionButton{
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statusBar{
  background-color:white
}
.tagSection{
  padding: $global-padding;
}
.tagSectionHeading{
  margin-bottom: 21px;
}
.tagSectionIcon{
  margin-right: 13px;
}
.tagList{
  display:flex;
  flex-wrap: wrap;
}
.tagListEntry{
  margin: 5px;
}
.tag{
  cursor: pointer;
}
.tooltip{
  padding: 0;
}