@import 'theme/voith-opl';

.icon{
  width:32px;
  height: 32px;
  fill: currentColor;
  margin-right: 12px;
}
.serviceHours{
  @extend .typo-pop-up-content;
  display: flex;
  align-items: center;
  span{
    margin: 0 10px;
    font-size: 24px;
  }
}

