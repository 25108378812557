@import 'theme/voith-opl';
.caption{
  @extend .typo-label;
  margin-bottom: 20px;
}
.title{
  @extend .typo-card-title;
  padding: 25px 0 15px 0;
}
.details{
  padding: 25px 0;
}