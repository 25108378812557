@import 'theme/voith-opl';

.button{
  margin: 40px;
  position: fixed;
  z-index: 1;
  top: calc(100% - 54px + -80px);
  right: 0;
  background-color: white;
  height: 54px;
  width: 54px;
  border-radius: 7px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  transition: .2s transform ease-in-out;
  display:flex;
  align-items: center;
  justify-content: center;
  &:hover{
    opacity: 1;
    transform: scale(1.1);
  }
}