@import 'theme/voith-opl';

.inputContainer{
  position: relative;
  align-items: stretch;
  width: 100%;
  display:flex;
  flex-direction: column;
  max-width: $global-input-field-max-width;
}

.inputTranslation{
  @extend .typo-paragraph;
  width: 90%;
  border-bottom: 1px solid $color-voith-blue-hover;
  padding: 10px 0;
  transition: .2s border ease-in-out;
  resize: none;
  &::placeholder{
    transition: .2s opacity ease-in-out;
  }
  &:focus{
    border-bottom: 1px solid $color-active-blue;
    outline: none;
    &::placeholder{
      opacity: 0.6;
    }
  }
}

.translation {
  display: flex;
  margin: 10px auto;
}

.dropdown {
  width: 280px;
}

.icon {
  font-size: 24px;
  margin-top: 20px;
}
