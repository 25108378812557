@import 'theme/voith-opl';

.section{
  padding: 20px 40px 0px 40px;
}
.sectionTitle{
  @extend .typo-small-sub-headline;
  color: $color-gray-40;
  margin-bottom: 32px;
}
.sectionTitleLarge{
  @extend .typo-card-title;
  margin-bottom: 32px;
} 
.sectionTitleHuge{
  @extend .typo-headline-small;
  line-height: 52px;
  margin-bottom: 32px;
}
.structurePath{
  margin-bottom: 64px;
}
.malfunctionTitle{
  margin-bottom: 24px;
}
.roundParagraph{
  padding: 10px 60px;
  border: 2px solid currentColor;
  border-radius: 4px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  position: relative;
  p{
    color: $color-voith-black;
  }
}
.roundParagraphIcon{
  font-size: 28px;
  line-height: 28px;
  position: absolute;
  left: 20px;
  &::before{
    color: $color-voith-black;
  }
}

.roundParagraphText{
  color: black;
}

.hsiDescription {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 32px;
}

.description {
  font-family: "HelveticaNeueMedium";
  font-weight: 400;
  font-size: 28px;
}
