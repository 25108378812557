@import 'theme/voith-opl';

.preline{
  @extend .typo-paragraph;
  color: $color-gray-40;
  margin-bottom: 20px;
  padding: 0 15px;
}
.title{
  @extend .typo-headline-small;
  color: $color-voith-black;
  padding: 0 15px;
  margin-bottom: 40px;
}
