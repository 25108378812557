@import 'theme/voith-opl';
.normal{
  @extend .typo-paragraph-highlight;
  margin-bottom: 21px;
}
.small{
  @extend .typo-label;
  margin-bottom: 18px;
}
.icon{
  margin-right: 13px;
}