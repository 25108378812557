.containerFull,
.container{
  padding: 1.5cm 1.5cm 3cm 1.5cm;
  background-color:white;
  margin-bottom: 50px;
}
.containerFull{
  padding: 0;
}
.header, .footer{
  display: block;
  position: fixed;
  z-index: 999;
}

.header{
  top: 0;
  right: 0;
}

.footer{
  left:0;
  bottom:0;
  width: 100%;
  max-height: 180px;
}

table {
  width: 100%;
}

.header, .headerSpace
{
  display:none;
  height: 40px;
}

figure {
    img{
    max-height: auto;
  }
  > div {
    border: none !important;
  }
}


@media print {
  .container,
  .containerFull {page-break-after: always}
  .header{
    display: block;
  }

  .footer{
    display: block;
  }

  img{
    max-height: 20cm;
    padding: 0.3cm;
    display:block;
  }

  figure {
    page-break-inside: avoid;
      img{
      max-height: auto;
    }
    > div {
      border: none !important;
    }
  }

}
