@import 'theme/voith-opl';

.screen{
  background-color:white;
  > section{
    padding-left: 25px;
    padding-right: 25px;
  }
}

.typeEntry{
  display:flex;
  justify-content: space-between;
  max-width: $global-input-field-max-width;
  >*{
    min-width: 300px;
  }
}

.label{
  @extend .typo-label;
  margin-bottom: 15px;
}

.highlightDiv {
  margin-top: 45px;
  margin-left: 10px;
}
