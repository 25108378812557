@import 'theme/voith-opl';

.container {
  padding:20px;
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 269px;
  padding: 10px 16px;
  margin: 8px 4px;
  border: dashed 1px $color-gray-20;
  height: 163px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-blue;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: $color-voith-black;
  }
}