@import 'theme/voith-opl';

.button{
  font-size: 24px;
  color: #2D4275;
}

.action{
  @extend .typo-label;
  color: $color-voith-blue;
}

.actions{
  padding: 1em;
}