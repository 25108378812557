@import "theme/voith-opl";

.section{
  position: relative;
  margin-top: $section-margin;
  margin-bottom: $section-margin;
}
.overlay{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: $color-gray-40;
  padding: $global-padding;
  margin: 0;
  > *{
    max-width: 1150px;
    margin: 0 auto;
  }
}
.actionButton{
  position: absolute;
  font-size: 24px;
  right: $global-padding;
  top: $global-padding;
}
.noMargin{
  margin-top: 0;
  margin-bottom: 0;
}
.title{
  @extend .typo-paragraph-highlight;
  margin-bottom: 25px;
}
.small{
  .title{
    @extend .typo-label;
    padding-left: 15px;
  }
}
.white{
  background-color: white;
  .title{
    padding: $global-padding 0;
    border-bottom: 1px solid $color-gray-20;
    margin-bottom: 0;
  }
}
.padding{
  padding: $global-padding*2 0;
}
.borderTop{
  border-top: 1px solid $color-gray-20;
}
.borderBottom{
  border-bottom: 1px solid $color-gray-20;
}
.row{
  display:flex;
  >*{
    flex:1;
  }
}