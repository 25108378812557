@import 'theme/voith-opl';
.heading{
  .title{
    @extend .typo-pop-up-content;
    margin-bottom: 15px;
  }
  .subTitle{
    overflow-wrap: break-word;
    @extend .typo-card-title;
  }
}
.batch{
  margin-bottom: 20px;
}
.timeStamp{
  @extend .typo-label;
  color: $color-gray-40;
}
.commentCount{
  display: flex;
  justify-content: flex-end;
  font-size: 5px;
}
.attachedCards,
.heading{
  text-align: left;
}
.heading,
.batch{
  align-self: flex-start;
}
.timeStamp,
.attachedCards{
  align-self: flex-end;
}
