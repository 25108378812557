.markdownDefault{
  h1{
    @extend .typo-headline-small;
  }

  h2{
    @extend .typo-card-title;
  }
  h3{
    @extend .typo-paragraph-highlight;
  }
  h4{
    @extend .typo-small-sub-headline;
  }
  h5{
    @extend .typo-label;
  }

  h1,h2,h3,h4{
    margin-bottom: 1.2em;
  }

  >p{
    margin-bottom: 2em;
  }
  >p:only-child{
    margin-bottom: 0;
  }
  strong{
    font-weight: 700;
    font-family: "HelveticaNeueMedium";
  }
  ul{
    margin: 1.5em;
    li{
      margin-left: 0.5em;
      list-style: disc;
      margin: 0.5em;
    }
  }
  ol{
    margin: 1.5em;
    li{
      margin-left: 0.5em;
      margin: 0.5em;
      list-style: decimal;
    }
  }
  a{
    text-decoration: underline;
    color: $color-voith-blue-hover;
    font-family: "HelveticaNeueMedium";
  }
  table{
    width: 100%;
    margin-bottom: 1.2em;
  }
  thead{
    @extend .typo-small-sub-headline;
    text-align:left;
  }
  tr{
    height: 60px;
  }
  th,
  td{
    border-bottom: 1px solid $color-gray-10;
  }
  td{
    @extend .typo-pop-up-content;
  }
}