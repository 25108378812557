@import "theme/voith-opl";

.container
{
  display:flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid $color-gray-20;
  padding: $global-padding;
  >*{
    display: flex;
    align-items: center;
  }
  height: 78px;
}

.container.small{
  height: 50px;
}

.container.huge{
  height: 108px;
}

.preline{
  @extend .typo-pop-up-content
}
.title{
  @extend .typo-paragraph-highlight
}
.prelineHuge{
  @extend .typo-pop-up-content
}
.titleHuge{
  @extend .typo-card-title
}
.backButton{
  font-size: 24px;
  margin-right: 15px;
}
.actionButton{
  font-size: 24px;
}
.actionButtons{
  >*:not(:first-child):not(:last-child){
    margin: 0 10px;
  }
  >:first-child{
    margin-right: 10px;
  }
  >:last-child{
    margin-left: 10px;
  }
  >:only-child{
    margin: 0;
  }
}