
.malfunction {
  cursor: pointer;
}

.group {
  cursor: pointer;
}

.st0, .st1, .st2, .st3, .st4, .st5, .st6, .st7, .st8, .st9, .st10, .st11, .st12, .st13, .st14, .st15 {
  stroke-width: 1.3px;
}

.st0, .st1, .st2, .st3, .st4, .st5, .st16, .st7, .st8, .st11, .st12, .st17, .st18, .st19, .st20, .st14, .st21, .st15 {
  stroke: #202020;
}

.st0, .st1, .st3, .st4, .st5 {
  stroke-linecap: round;
}

.st0, .st1, .st3, .st5 {
  stroke-linejoin: round;
}

.st0, .st22, .st7 {
  fill: #ddd;
}

.st1, .st2, .st3, .st4, .st5, .st6, .st9, .st10, .st13, .st19, .st20, .st14 {
  fill: none;
}

.st2 {
  stroke-dasharray: 1.8 1.8 1.8 1.8 1.8 1.8;
}

.st2, .st4, .st6, .st16, .st7, .st8, .st9, .st10, .st11, .st12, .st17, .st18, .st13, .st19, .st20, .st14, .st21, .st15 {
  stroke-miterlimit: 2.1;
}

.st3 {
  stroke-dasharray: 0 3.6;
}

.st5 {
  stroke-dasharray: 0 5.2;
}

.st6 {
  stroke: #6f6f6f;
}

.st16, .st8 {
  fill: #9c9c9c;
}

.st16, .st17, .st20, .st21 {
  stroke-width: 1.2px;
}

.st9 {
  stroke: #ddd;
}

.st10 {
  stroke: #ededed;
}

.st11 {
  fill: #6f6f6f;
}

.st12, .st17 {
  fill: #fff;
}

.st18 {
  fill: #f6f6f6;
  stroke-width: .9px;
}

.st13 {
  stroke: #9c9c9c;
}

.st19 {
  stroke-width: 1.2px;
}

.st21, .st15 {
  fill: #ededed;
}
