@import 'theme/voith-opl';

.malfunction{
  cursor: pointer;
}

.inner {
  min-height: 250px !important;
}

.info{
  padding: 0;
  opacity: 1 !important;
}

.card {
  width: 252px;
  height: 74px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

  span {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 24px;
  }

  h4 {
    font-family: "HelveticaNeueRegular";
    color: $color-voith-black;
    font-size: 14px;
    line-height: 20px;
  }
}