@import 'theme/voith-opl';

.container{
  background-color: white;
}
.preline{
  @extend .typo-paragraph;
  color: $color-gray-40;
}
.title{
  @extend .typo-headline-small;
  color: $color-voith-black;
  margin-top: 10px;
}
.notes,
.status
{
  flex: 1 0 100%;
  border-bottom: 1px solid $color-gray-20;
}
.summary{
  padding: 50px 0 0 0;
}