@import 'theme/voith-opl';

.container{
  position: fixed;
  top: 0;
  left:0;
  z-index:1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  display:flex;
  justify-content: center;
  align-items: center;
  animation: backdrop-fade-in .2s;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.inner{
  width: 1000px;
  background-color: white;
  position: relative;
  opacity: 0;
  border-radius: 10px;
  overflow:hidden;
  animation: modal-animation .3s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  flex-wrap: wrap;
  padding: 20px 0 40px 0;
  > section{
    flex: 1 0 100%;
  }
}

.header{
  padding: 0 10px;
}

.TextField,
.footer
{
  padding: 0 30px;
}

.multiLineInput{
  max-width: 100%;
}

.footer{
  padding-bottom: 20px;
  display:flex;
}

.footerLeft,
.footerRight
{
  flex:1;
}
.footerRight{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.sendButton{
  margin-right: 0;
}
@keyframes modal-animation{
  0%{
    transform: translateY(-50px);
    opacity:0;
  }
  100%{
    transform: translateY(0);
    opacity: 10;
  }
}
@keyframes backdrop-fade-in{
  0%{
    background-color: rgba(0,0,0,0);
  }
  100%{
    background-color: rgba(0,0,0,0.5);
  }
}