@import 'theme/voith-opl';

.card {
  width: 252px;
  display: flex;
  height: 74px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0.1em 0.3em rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  background: $color-white;
  cursor: grab;
  text-align: center;
  padding: 0 10px;

  p {
    @extend .typo-paragraph-label;
    color: $color-voith-black;
  }
}

.isGrabbing {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  padding-top: 31px;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  cursor: grabbing;
}

.text {
  opacity: 0;
}

.didDrop {
  cursor: not-allowed;
  // pointer-events: none;
  background: rgba(100, 100, 100, 0.1);
}