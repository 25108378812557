@import 'theme/voith-opl';

.container{
  position: fixed;
  top: 0;
  left:0;
  z-index:9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  display:flex;
  justify-content: center;
  align-items: center;
  animation: backdrop-fade-in .2s;
}
.inner{
  min-width: 430px;
  max-width: 600px;
  min-height: 300px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  opacity: 0;
  animation: modal-animation .3s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  border-top:5px solid currentColor;
}
.close{
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 32px;
  ::before{
    color:currentColor
  }
}
.title{
  @extend .typo-paragraph-highlight;
  text-transform: none;
  align-self: flex-start;
  flex: 0;
  margin-bottom: 25px;
}
.message{
  @extend .typo-button;
  align-self: flex-start;
  flex:1;
  color: $color-gray-40;
  max-width: 300px;
}
.buttons{
  flex: 0;
  width: 100%;
}
.wideButton{
  width: 100%;
}
.toggleButton{
  margin: 15px 0;
}
.warning{
  color:$color-yellow-warning;;
}
.error{
  color:$color-red-error;
}
.alert{
  color:$color-voith-blue;
}
.wait{
  justify-content: center;
  border-top:none;
}

@keyframes modal-animation{
  0%{
    transform: translateY(-50px);
    opacity:0;
  }
  100%{
    transform: translateY(0);
    opacity: 10;
  }
}
@keyframes backdrop-fade-in{
  0%{
    background-color: rgba(0,0,0,0);
  }
  100%{
    background-color: rgba(0,0,0,0.5);
  }
}