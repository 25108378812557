@import 'theme/voith-opl';
.container{
  height: 250px;
}
.inner{
  background-color: white;
  width: 100%;
  height: 100%;
}
.heading{
  .title{
    @extend .typo-card-title;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
  }
}
.structures{
  margin-bottom: 15px;
}
.structuresText{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
}
.structures,
.healthAssessmentsCount{
  @extend .typo-button;
  >:last-child{
    @extend .typo-pop-up-content;
    text-transform: none;
  }
}

.structureAssessments {
  padding-top: 15px;
  padding-left: 0px;
  padding-bottom: 15px;
  height: 100%;
  overflow-x: scroll;
  background: white;
}

.gridHeader {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
}

.gridRow {
 padding-bottom: 10px;
 font-size: 12px;
}

span.assessmentsLink {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

span:hover.assessmentsLink {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}
