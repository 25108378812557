@import 'theme/voith-opl';

.button{
  @extend .typo-button;
  position: relative;
  text-transform: uppercase;
  min-width: 175px;
  padding: 12px 20px;
  margin-right: 15px;
}
.sizeSmall{
  @extend .typo-button;
  text-transform: uppercase;
  padding: 6px 50px;
}
.typePrimary{
  composes: button;
  color: white;
  background-color: $color-voith-blue;
  &:hover{
    background-color: $color-voith-blue-hover;
  }
  &:disabled{
    cursor: not-allowed;
    background-color: $color-gray-40;
  }
}
.typeSecondary{
  composes: button;
  color: white;
  background-color: $color-gray-60;
  &:hover{
    background-color: $color-gray-40;
  }
  &:disabled{
    cursor: not-allowed;
    background-color: $color-gray-40;
  }
}
.typeOutlined{
  composes: button;
  color: $color-voith-blue;
  box-shadow: inset 0 0 0 2px currentColor;
  background-color: white;
  &:hover:not(:disabled){
    color: $color-voith-blue-hover;
    box-shadow: inset 0 0 0 2px $color-voith-blue-hover;
  }
  &:disabled{
    color: $color-gray-40;
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px currentColor;
  }
}
.colorWarning{
  color: $color-voith-black;
  background-color: $color-yellow-warning;
  &:hover{
    background: $color-yellow-warning-hover;
  }
}
.colorError{
  color: white;
  background-color: $color-red-error;
  &:hover{
    background: $color-red-error-hover;
  }
}
.colorGray{
  color: white;
  background-color: $color-gray-60;
  &:hover{
    background: $color-gray-40;
  }
}
.buttonWithError{

}
.errorText{
  color: $color-gray-40;
  position: absolute;
  text-transform: none;
  text-align: left;
  bottom: -45px;
  left:0px;
  font-size: 10px;
  height: 44px;
  width: 100%;
}


