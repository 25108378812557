@import 'theme/voith-opl';
.switch input {
  position: absolute;
  opacity: 0;
  &:checked + div{
    background: $color-active-blue;
  }
}

.checked .switch{
  background: rgba($color-active-blue, 0.3);
}

.switch {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  width: 2.5em;
  background:$color-gray-20;
  border-radius: 1em;
  margin: 20px;
  cursor: pointer;
}

.switch div {
  position: relative;
  left: -0.2em;
  top: -0.2em;
  height: 1.4em;
  width: 1.4em;
  border-radius: 1em;
  background: $color-voith-blue;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  -webkit-transition: all 300ms;
     -moz-transition: all 300ms;
          transition: all 300ms;
}

.switch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
     -moz-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
