
.malfunction {
  cursor: pointer;
}

.group {
  cursor: pointer;
}

.cls1, .cls10, .cls14, .cls3, .cls5, .cls9 {
  fill: none;
}

.cls1 {
  stroke: #ededed;
}

.cls1, .cls10, .cls11, .cls12, .cls13, .cls14, .cls19, .cls2, .cls20, .cls3, .cls5, .cls6, .cls7, .cls8, .cls9 {
  stroke-width: 1.5px;
}

.cls2 {
  fill: #ededed;
}

.cls10, .cls11, .cls12, .cls13, .cls14, .cls15, .cls16, .cls17, .cls18, .cls19, .cls2, .cls20, .cls6, .cls7, .cls8, .cls9 {
  stroke: #202020;
}

.cls3 {
  stroke: #6f6f6f;
}

.cls20, .cls4, .cls8 {
  fill: #ddd;
}

.cls5 {
  stroke: #9c9c9c;
}

.cls12, .cls6 {
  fill: #9c9c9c;
}

.cls19, .cls7 {
  fill: #fff;
}

.cls10 {
  stroke-dasharray: 2.08 2.08 2.08 2.08 2.08 2.08;
}

.cls11 {
  fill: #f6f6f6;
}

.cls12, .cls19, .cls20 {
  stroke-linejoin: bevel;
}

.cls13 {
  fill: #6f6f6f;
}

.cls14 {
  stroke-linecap: round;
}

.cls15, .cls16, .cls17, .cls18 {
  fill: #202020;
}

.cls15 {
  stroke-width: 1.5px;
}

.cls16 {
  stroke-width: 1.5px;
}

.cls17 {
  stroke-width: 1.5px;
}

.cls18 {
  stroke-width: 1.5px;
}