@import 'theme/voith-opl';

.container{
  display: flex;
  min-height: 100px;
}

.two{
  flex: 1 0 50%;
}
.three{
  flex: 1 0 33%;
}
.left,
.middle,
.right
{
  padding: 15px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  >*:first-child{
    @extend .typo-label;
  }
}
.left{
  border-right: 1px solid $color-gray-20;
}
.middle{
  border-right: 1px solid $color-gray-20;
}
.item{
  flex: 1;
  border-right: 1px solid $color-gray-20;
  padding: 30px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  &:last-child{
    border-right: none;
  }
}