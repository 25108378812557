@import 'theme/voith-opl';
.screen{
    background-color:white;
    >section{
      padding-left: 25px;
      padding-right: 25px;
    }
}
.dateEntry{
  display:flex;
  align-items: flex-start;
  >:first-child{
    margin-right: 100px;
  }
}

.turbineTypeDiv {
  margin-top: 30px;
}

.turbineTypeLabel {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.turbineType {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2D4275;
  padding-left: 7px;
}
