@import 'theme/voith-opl';

body{
  font-family: "HelveticaNeueRegular";
  color: $color-text;
  background-color:$color-gray-10;
}

header,
.app{
  padding: 0 25px;
  min-width: 960px;
  max-width: 1200px;
  margin: 0 auto;
}

.app{
  display:flex;
  flex-wrap: wrap;
}

.view{
  width: 100%;
  min-height: calc(100vh - 80px);
  margin-bottom: 100px;
}

button{
  transition: .1s opacity ease-in-out;
  >[class^="icon-"]{
    color: $color-voith-blue;
  }
  &:hover{
    opacity: 0.7;
  }
}

.warning{
  color: $color-yellow-warning;
}
.alert{
  color: $color-red-error;
}
.normal{
  color: $color-voith-blue;
}