.screen{
  background-color:white;
  >section {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.checkList {
  margin-bottom: 30px;
}

.sectionMalfunctionAC {
  padding-top: 40px;
  padding-bottom: 10px;
}

.label {
  font-weight: bold !important;
  font-size: 14px !important;
}

.sectionTitle {
  font-weight: bold !important;
  font-size: 14px !important;
}

.sectionFlex {
  display: flex;
}

.dropDownField {
  width: 37%
}

.leftMargin {
  margin-left: 50px;
}
