@import 'theme/voith-opl';

.checkBox{
  display:flex;
  align-items: center;
  cursor: pointer;
}

.description{
  @extend .typo-label;
  padding-left: 20px;
  user-select: none;
}

.input{
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border: 2px solid $color-voith-blue;
  cursor: pointer;
  &:checked{
    &::before{
      content: "";
      width: 15px;
      height: 15px;
      background-color: $color-voith-blue;
    }
  }
}

.radio{
  border-radius: 50%;
  &:checked{
    &::before{
      border-radius: 50%;
    }
  }
}